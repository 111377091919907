<template>
  <section class="containerSkeletonSavedSearches">
    <section class="containerSkeletonSavedSearches__noMobile" v-if="!store.state.phoneResolution">
      <div class="containerSkeletonSavedSearches__noMobile--item" v-for="i in items" :key=i>
        <div></div>
        <div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div></div>
      </div>
    </section>
    <section class="containerSkeletonSavedSearches__mobile" v-else>
      <div class="containerSkeletonSavedSearches__mobile--item" v-for="i in items" :key=i>
        <div>
          <div></div>
          <div></div>
        </div>
        <div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div></div>
      </div>
    </section>
  </section>
</template>

<script>

import { ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'skeletonSavedSearches',
  setup (props) {
    const store = useStore()
    const items = ref(10)

    return {
      store,
      items
    }
  }
}
</script>
