<template>
  <section class="containerSavedSearches">
    <section class="containerSavedSearches__info">
      <backButton altRedirection="resultsSearch"></backButton>
      <div class="containerSavedSearches__info--title">
        <span class="label">Saved Searches</span>
      </div>
      <section v-if="searchesLoaded && savedSearches.length" class="containerSavedSearches__info--list">
        <ul class="list">
          <li v-for="(savedSearch, index) in savedSearches" :key="index" class="list__item" @click="openSavedSearch(savedSearch)">
            <section v-if="deleteIndex === index" class="list__item--delete" @click.stop.prevent v-click-away="closeDeleteSearch">
              <section v-if="!store.state.phoneResolution" class="options">
                <span class="options__label">Delete this Saved Search?</span>
                <button class="options__delete" @click.stop="deleteSearch(savedSearch)" :disabled="isDeletingSearch">Delete</button>
                <button class="options__cancel" @click.stop="closeDeleteSearch">Cancel</button>
              </section>
              <section v-else class="optionsMobile">
                <span class="optionsMobile__label">Delete this Saved Search?</span>
                <div class="optionsMobile__buttons">
                  <button class="optionsMobile__buttons--delete" @click.stop="deleteSearch(savedSearch)" :disabled="isDeletingSearch">DELETE</button>
                  <button class="optionsMobile__buttons--cancel" @click.stop="closeDeleteSearch">CANCEL</button>
                </div>
              </section>
            </section>
            <section v-if="!store.state.phoneResolution" class="list__item--content">
              <span :class="savedSearch.icon" class="icon" />
              <section class="info">
                <span class="info__title">{{savedSearch.name}}</span>
                <div class="info__subfield">
                  <span class="info__subfield--title">Keywords:&nbsp;</span>
                  <span class="info__subfield--value box-vertical">{{savedSearch.searchTerms}}</span>
                </div>
                <div class="info__subfield">
                  <span class="info__subfield--title">Filtered by:&nbsp;</span>
                  <span class="info__subfield--value box-vertical">{{searchFiltersLabel(savedSearch.searchFilters)}}</span>
                </div>
              </section>
            </section>
            <div class="list__item--icons"  v-if="!store.state.phoneResolution">
              <span class="icon grey-icon-edit-work" @click="editSavedSearch($event, savedSearch)" />
              <span class="icon icon-grey-delete" @click="openDeleteSearch($event, index)" />
            </div>
            <section v-if="store.state.phoneResolution" class="list__item--mobile">
              <div class="header">
                <span :class="savedSearch.icon" class="header__icon" />
                <span class="header__title">{{savedSearch.name}}</span>
              </div>
              <section class="infoMobile">
                <div class="infoMobile__subfield">
                  <span class="infoMobile__subfield--title">Keywords:&nbsp;</span>
                  <span class="infoMobile__subfield--value box-vertical">{{savedSearch.searchTerms}}</span>
                </div>
                <div class="infoMobile__filtersDesc box-vertical"><span class="infoMobile__filtersDesc--title">Filtered by:&nbsp;</span>{{searchFiltersLabel(savedSearch.searchFilters)}}</div>
              </section>
              <div class="iconsMobile">
                <span class="grey-icon-edit-work" @click="editSavedSearch($event, savedSearch)" />
                <span class="icon-grey-delete" @click="openDeleteSearch($event, index)" />
              </div>
            </section>
          </li>
        </ul>
      </section>
      <section v-if="searchesLoaded && !savedSearches.length" class="containerSavedSearches__info--noSavedSearches">
        <img class="empty-icon" src="@/assets/SavedSearch-01.svg" />
        <span class="label">No Searches have been saved yet!</span>
      </section>
      <skeletonSavedSearches v-if="!searchesLoaded"></skeletonSavedSearches>
      <paginator v-if="searchesLoaded"
        :totalItems="totalResults"
        :maxPages="store.state.saveSearchPagination.maxPages"
        :pagination="store.state.saveSearchPagination"
        @goToPage="goToPage"
      >
      </paginator>
    </section>
    <toast v-if="deleteErrorToast" :class="{'marginToolToast': !store.state.isMobile}"
      :message="errorMessageToast">
    </toast>
  </section>
</template>

<script>
import { onMounted, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import messages from '../../messages.json'
import paginator from '@/components/paginator/paginator'
import backButton from '@/components/backButton/backButton'
import skeletonSavedSearches from '@/components/skeletons/skeletonSavedSearches/skeletonSavedSearches'

export default {
  name: 'savedSearches',
  components: {
    paginator,
    backButton,
    skeletonSavedSearches
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const searchesLoaded = ref(false)
    const savedSearches = ref([])
    const deleteIndex = ref(null)
    const isDeletingSearch = ref(false)
    const errorMessageToast = messages.generic.errorMessageToast
    const deleteErrorToast = ref(null)
    const totalResults = ref(0)

    /**
     * @description Return filtered by label
     * @param searchFilters filters applied on the search
     */
    const searchFiltersLabel = computed(() => {
      return searchFilters => {
        return searchFilters.map(searchFilter => searchFilter.description).join(' + ')
      }
    })

    onMounted(() => {
      setUpPage(false)
    })

    /**
     * @description Initial page setup
     * @param reload flag indicating if page is being reloaded
     */
    function setUpPage (reload) {
      if (!reload) {
        store.commit({
          type: 'SET_SAVED_SEARCHES_PAGINATION',
          size: route.query.t,
          page: route.query.k
        })
      }
      searchesLoaded.value = false
      store.dispatch({
        type: 'getSavedSearches'
      }).then(response => {
        if (response.data.success) {
          savedSearches.value = [
            ...response.data.values.map(item => ({
              ...item,
              icon: setInfoBySource(item.source, true)
            }))
          ]
          totalResults.value = response.data.total
        }
        searchesLoaded.value = true
        if (navigator.vendor.toLocaleLowerCase().includes('apple')) {
          document.getElementsByClassName('containerSavedSearches')[0].classList.add('safariStyle')
        }
      })
    }

    /**
     * @description Set information of the search by the source
     * @param source source saved with the search
     * @param icon flag indicating if is icon information
     */
    function setInfoBySource (source, icon) {
      let infoBySource = ''
      switch (source) {
        case 'all sites':
          infoBySource = icon ? 'grey-icon-allSources' : 'All sites'
          break
        case 'office365':
          infoBySource = icon ? 'icon-grey-Office' : 'Office365'
          break
        case 'go grey':
          infoBySource = icon ? 'grey-icon-gogrey' : 'Go Grey'
          break
        default:
          infoBySource = icon ? `icon-grey-${source.charAt(0).toUpperCase() + source.slice(1)}` : source.charAt(0).toUpperCase() + source.slice(1)
          break
      }
      return infoBySource
    }

    /**
     * @description open a new tab with the selected search performed
     * @param savedSearch search to be redirected
     */
    function openSavedSearch (savedSearch) {
      const filtersUrl = savedSearch.searchFilters.map(searchFilter => {
        return `${searchFilter.category}::${searchFilter.description.replace(/&/g, '%26')}`
      }).join(',,')
      const orderBy = savedSearch.orderBy.replace('search.score()', 'Relevance')
      const searchTerm = savedSearch.searchTerms.replace(/&/g, '%26')
      const searchUrl = `${process.env.VUE_APP_SEARCH_HOST}${window.location.host}/resultsSearch?t=8&k=1&q=${searchTerm}&s=${setInfoBySource(savedSearch.source, false)}&f=${filtersUrl}&o=${orderBy}`
      window.open(searchUrl, '_blank')
    }

    /**
     * @description open delete search component
     * @param event DOM event
     * @param index index of the element
     */
    function openDeleteSearch (event, index) {
      event.preventDefault()
      event.stopPropagation()
      deleteIndex.value = index
    }

    /**
     * @description Delete selected saved search
     * @param savedSearch search to be deleted
     */
    function deleteSearch (savedSearch) {
      store.dispatch({
        type: 'deleteSavedSearch',
        searchId: savedSearch.id
      }).then((response) => {
        if (response.success) {
          if (savedSearches.value.length === 1 && store.state.saveSearchPagination.page > 1) {
            store.commit({
              type: 'SET_SAVED_SEARCHES_PAGINATION',
              size: store.state.saveSearchPagination.size,
              page: store.state.saveSearchPagination.page - 1
            })
            store.commit({
              type: 'UPDATE_SAVED_SEARCHES_URL'
            })
          }
          setUpPage(true)
          deleteIndex.value = null
        } else {
          showDeleteErrorToast()
        }
      }).catch((error) => {
        console.error(error)
        showDeleteErrorToast()
      })
    }

    /**
     * @description show toast with delete item fail message.
     */
    function showDeleteErrorToast () {
      deleteErrorToast.value = true
      setTimeout(() => {
        deleteErrorToast.value = false
      }, 5000)
    }

    /**
     * @description close delete saved search modal
     */
    function closeDeleteSearch () {
      deleteIndex.value = null
    }

    /**
     * @description Edit saved search
     * @param event DOM event
     * @param savedSearch search to be edited
     */
    function editSavedSearch (event, savedSearch) {
      event.preventDefault()
      event.stopPropagation()
      store.commit({
        type: 'SET_SEARCH_SAVED_INFORMATION',
        searchSaved: true,
        searchId: savedSearch.id
      })
      store.commit({
        type: 'SET_SEARCH_EDITING_INFORMATION',
        isEditingSearch: true,
        editingSearchInfo: {
          name: savedSearch.name,
          source: savedSearch.source,
          searchTerms: savedSearch.searchTerms,
          searchFilters: savedSearch.searchFilters,
          orderBy: savedSearch.orderBy
        }
      })
      const filtersUrl = savedSearch.searchFilters.map(searchFilter => {
        return `${searchFilter.category}::${searchFilter.description}`
      }).join(',,')
      const orderBy = savedSearch.orderBy.replace('search.score()', 'Relevance')
      const searchTerm = savedSearch.searchTerms
      router.push({ name: 'resultsSearch', query: { t: 8, k: 1, q: searchTerm, s: setInfoBySource(savedSearch.source, false), f: filtersUrl, o: orderBy } })
      document.getElementById('app').scrollTo(0, 0)
    }

    /**
     * @description Execute the action required when a new page is selected
     * @param pageNumber Number of the page selected
     */
    function goToPage (pageNumber) {
      store.commit({
        type: 'SET_SAVED_SEARCHES_PAGINATION',
        size: store.state.saveSearchPagination.size,
        page: pageNumber
      })
      store.commit({
        type: 'UPDATE_SAVED_SEARCHES_URL'
      })
      setUpPage(true)
    }

    return {
      store,
      router,
      route,
      searchesLoaded,
      savedSearches,
      deleteIndex,
      isDeletingSearch,
      errorMessageToast,
      deleteErrorToast,
      totalResults,
      searchFiltersLabel,
      openSavedSearch,
      openDeleteSearch,
      deleteSearch,
      closeDeleteSearch,
      editSavedSearch,
      goToPage
    }
  }
}
</script>
